import { FetchQueryOptions, useQuery } from '@tanstack/react-query';

import { REVALIDATE } from '@/entities/constants';
import { FullNews } from '@/shared/api/codegen/demuzo';
import { newsApi } from '@/shared/api/config';

import { newsKeys } from '../query-keys';

type Props = {
  newsId: string;
  initialData?: FullNews;
};

export const getNewsByIdPrefetchConfig = (newsId: string, headers?: any) =>
  ({
    queryKey: [...newsKeys.getNewsById(newsId)],
    queryFn: () =>
      newsApi.getNewsNewsId(
        { newsId },
        { headers, next: { revalidate: REVALIDATE } },
      ),
  }) satisfies FetchQueryOptions;

export const useGetNewsById = ({ newsId, initialData }: Props) => {
  return useQuery({
    queryKey: [...newsKeys.getNewsById(newsId)],
    queryFn: () => newsApi.getNewsNewsId({ newsId }),
    initialData,
    refetchOnMount: !initialData,
  });
};
