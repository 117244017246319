import { createIcon } from '@chakra-ui/react';
import { FC, memo } from 'react';

import { iconSizes } from './constants';
import { IconPropsWithSize } from './types';

const Ic = createIcon({
  viewBox: '0 0 24 24',
  path: (
    <path
      d='M7 14L12 9L17 14H7Z'
      fill='currentColor'
    />
  ),
});

const IconArrowDropUpFilled: FC<IconPropsWithSize> = ({
  size = 'lg',
  ...props
}) => (
  <Ic
    {...iconSizes[size]}
    {...props}
  />
);

export default memo(IconArrowDropUpFilled);
