import { Box, Flex, LinkBox, LinkOverlay, Text } from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import type { FC } from 'react';

import { formatDate } from '@/shared/utils/format-date';

import { NewData } from './types';

type Props = NewData & {
  href: string;
  variant?: 'home-page' | 'default';
};

export const NewsCard: FC<Props> = ({
  title,
  duration,
  publishDate,
  preview,
  coverUrl,
  href,
  variant = 'default',
}) => {
  const t = useTranslations();

  return (
    <LinkBox
      as={Flex}
      cursor='pointer'
      flexDirection='column'
      width={variant === 'home-page' ? '245px' : { base: 'full' }}
      borderWidth={variant === 'home-page' ? '0' : { base: '0', xl: '1px' }}
      borderStyle={variant === 'home-page' ? '0' : { base: '0', xl: 'solid' }}
      borderRadius={
        variant === 'home-page' ? '20px' : { base: '20px', xl: '30px' }
      }
      borderColor={
        variant === 'home-page'
          ? 'none'
          : { base: 'none', xl: 'stroke.st-secondary' }
      }
      bg={
        variant === 'home-page'
          ? 'background.bg-primary'
          : { base: 'background.bg-primary', xl: 'none' }
      }
      overflow='hidden'
      h={variant === 'home-page' ? 'auto' : { xl: '735px' }}
    >
      {coverUrl !== null && (
        <Box
          padding={
            variant === 'home-page'
              ? '20px 0 0'
              : { base: '20px 0 0', xl: '40px' }
          }
          order={variant === 'home-page' ? '3' : { base: '3', xl: '1' }}
        >
          <Box
            borderRadius={variant === 'home-page' ? '0' : { xl: '10px' }}
            w='full'
            h={
              variant === 'home-page' ? '165px' : { base: '165px', xl: '355px' }
            }
            bgImage={coverUrl}
            bgSize='cover'
            bgPos='center'
            bgRepeat='no-repeat'
          />
        </Box>
      )}

      <LinkOverlay
        href={href}
        p={variant === 'home-page' ? '20px' : { base: '20px', xl: '40px' }}
        order={variant === 'home-page' ? '1' : { base: '1', xl: '2' }}
      >
        <Text
          textStyle={
            variant === 'home-page'
              ? 'one-line-text-medium-xl'
              : { base: 'one-line-text-medium-xl', xl: 'heading-large-2xl' }
          }
          noOfLines={variant === 'home-page' ? 1 : { base: 1, xl: 0 }}
          overflow={
            variant === 'home-page'
              ? 'hidden'
              : { base: 'hidden', xl: 'visible' }
          }
        >
          {title}
        </Text>
      </LinkOverlay>

      {coverUrl === null && (
        <Box
          p={
            variant === 'home-page'
              ? '20px'
              : { base: '20px', xl: '0 40px 40px' }
          }
          order={variant === 'home-page' ? '3' : { base: '3', xl: '2' }}
        >
          <Text
            minH={
              variant === 'home-page' ? '145px' : { base: '145px', xl: 'auto' }
            }
            textStyle='one-line-text-regular-sm'
            color='grey.500'
            noOfLines={7}
          >
            {preview}
          </Text>
        </Box>
      )}

      <Box
        display={
          variant === 'home-page' ? 'none' : { base: 'none', xl: 'block' }
        }
        flex={1}
        order={3}
      />

      <Box order={variant === 'home-page' ? '2' : { base: '2', xl: '3' }}>
        <Text
          px={variant === 'home-page' ? '20px' : { base: '20px', xl: '40px' }}
          pb={variant === 'home-page' ? '0' : { xl: '40px' }}
          fontSize='12px'
          lineHeight='120%'
          letterSpacing='-0.04em'
          color='text&icon.tx-secondary'
        >
          {t('NewsCard.duration', { duration })}
          <Text
            as='span'
            px='6px'
          >
            ·
          </Text>
          {publishDate ? formatDate({ date: publishDate }) : null}
        </Text>
      </Box>
    </LinkBox>
  );
};
export default NewsCard;
