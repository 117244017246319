import { Box } from '@chakra-ui/react';
import { FC } from 'react';
import { Controller, FreeMode } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperType } from 'swiper/types';

import { useIsMobile } from '@ui-kit/hooks/useIsMobile';
import NewsCard from '@ui-kit/molecules/news-card/news-card';
import { NewData } from '@ui-kit/molecules/news-card/types';

const swiperModules = [FreeMode, Controller];

type Props = {
  newsData: NewData[];
  controlledSwiperNews: SwiperType | undefined;
  setControlledSwiperNews: (swiper: SwiperType) => void;
};

export const NewsSwiper: FC<Props> = ({
  newsData,
  controlledSwiperNews,
  setControlledSwiperNews,
}) => {
  const isMobile = useIsMobile();

  return (
    <Box mt='20px'>
      <Swiper
        spaceBetween={10}
        slidesOffsetAfter={isMobile ? 20 : undefined}
        slidesOffsetBefore={isMobile ? 20 : undefined}
        slidesPerView='auto'
        freeMode={true}
        grabCursor
        modules={swiperModules}
        onSwiper={setControlledSwiperNews}
        controller={{ control: controlledSwiperNews }}
      >
        {newsData.map((cardData, index) => (
          <SwiperSlide
            key={cardData.id}
            virtualIndex={index}
            style={{ width: 245, paddingBottom: 20 }}
          >
            <NewsCard
              variant='home-page'
              href={`/news/${cardData.id}`}
              {...cardData}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};
