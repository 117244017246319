import { createIcon } from '@chakra-ui/react';
import { FC, memo } from 'react';

import { iconSizes } from './constants';
import { IconPropsWithSize } from './types';

const Ic = createIcon({
  viewBox: '0 0 24 24',
  path: (
    <path
      d='M7 10L12 15L17 10H7Z'
      fill='currentColor'
    />
  ),
});

const IconArrowDropDownFilled: FC<IconPropsWithSize> = ({
  size = 'lg',
  ...props
}) => (
  <Ic
    {...iconSizes[size]}
    {...props}
  />
);

export default memo(IconArrowDropDownFilled);
