import { Box } from '@chakra-ui/react';
import { FC } from 'react';

type Props = {
  isActive: boolean;
};

export const CarouselPositionItem: FC<Props> = ({ isActive }) => {
  return (
    <Box
      rounded={50}
      bgColor={isActive ? 'white' : 'gray.500'}
      borderColor='white'
      width={{ base: '4px', md: '8px' }}
      height={{ base: '4px', md: '8px' }}
    />
  );
};

export default CarouselPositionItem;
