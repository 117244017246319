import { useInfiniteQuery } from '@tanstack/react-query';

import { LIMIT } from '@/entities/constants';
import { newsKeys } from '@/entities/news/model/query-keys';
import {
  GetNews200,
  GetNewsV1UserNewsUserIdError,
} from '@/shared/api/codegen/demuzo';
import { newsApi } from '@/shared/api/config';

export const useNewsV1UserNewsUserIdInfinity = (userId?: string) => {
  return useInfiniteQuery<GetNews200, GetNewsV1UserNewsUserIdError>({
    enabled: !!userId,
    initialPageParam: 0,
    getNextPageParam: (lastPage) => {
      if (lastPage.total > lastPage.limit + lastPage.offset) {
        return lastPage.offset / lastPage.limit + 1;
      }
      return undefined;
    },
    queryKey: newsKeys.getUserNews(userId!),
    queryFn: ({ pageParam }) =>
      newsApi.getNewsV1UserNewsUserId({
        limit: LIMIT,
        offset: (pageParam as number) * 10,
        userId: userId!,
      }),
  });
};
