export { getNewsPrefetchConfig, useGetNews } from './model/hooks/use-get-news';
export {
  getNewsByIdPrefetchConfig,
  useGetNewsById,
} from './model/hooks/use-get-news-by-id';
export { useGetNewsInfinity } from './model/hooks/use-get-news-infinity';
export {
  getProductNewsPrefetchConfig,
  useGetProductNews,
} from './model/hooks/use-get-product-news';
export { useNewsV1UserNewsUserIdInfinity } from './model/hooks/use-get-user-news';
