import { FetchQueryOptions, useQuery } from '@tanstack/react-query';

import { REVALIDATE } from '@/entities/constants';
import { GetProductNews200 } from '@/shared/api/codegen/demuzo';
import { newsApi } from '@/shared/api/config';

import { newsKeys } from '../query-keys';

type Props = {
  productId: string;
  initialData?: GetProductNews200;
};

export const getProductNewsPrefetchConfig = (
  productId: string,
): FetchQueryOptions => ({
  queryKey: [...newsKeys.getProductNews(productId)],
  queryFn: () =>
    newsApi.getNewsV1ProductProductId(
      { productId },
      { next: { revalidate: REVALIDATE } },
    ),
});

export const useGetProductNews = ({ productId, initialData }: Props) => {
  return useQuery({
    queryKey: [...newsKeys.getProductNews(productId)],
    queryFn: () => newsApi.getNewsV1ProductProductId({ productId }),
    initialData: initialData,
    refetchOnMount: !initialData,
  });
};
