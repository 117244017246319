import { FetchQueryOptions, useQuery } from '@tanstack/react-query';

import { LIMIT, OFFSET, REVALIDATE } from '@/entities/constants';
import { GetNews200 } from '@/shared/api/codegen/demuzo';
import { newsApi } from '@/shared/api/config';

import { newsKeys } from '../query-keys';

type Props = {
  limit: number;
  offset: number;
  initData?: GetNews200;
};

export const getNewsPrefetchConfig = () =>
  ({
    queryKey: [...newsKeys.getNews({ limit: LIMIT, offset: OFFSET })],
    queryFn: () =>
      newsApi.getNews(
        { limit: LIMIT, offset: OFFSET },
        { next: { revalidate: REVALIDATE } },
      ),
  }) satisfies FetchQueryOptions;

export const useGetNews = ({ limit, offset, initData }: Props) => {
  return useQuery({
    queryKey: [...newsKeys.getNews({ limit, offset })],
    queryFn: () => newsApi.getNews({ offset, limit }),
    initialData: initData,
    refetchOnMount: !initData,
  });
};
