import { InfiniteData, useInfiniteQuery } from '@tanstack/react-query';

import { LIMIT } from '@/entities/constants';
import { GetNews200 } from '@/shared/api/codegen/demuzo';
import { newsApi } from '@/shared/api/config';

import { newsKeys } from '../query-keys';

export const useGetNewsInfinity = (
  initData?: InfiniteData<GetNews200, number>,
) => {
  return useInfiniteQuery({
    getNextPageParam: (lastPage: GetNews200) => {
      if (lastPage.total > lastPage.limit + lastPage.offset) {
        return lastPage.offset / lastPage.limit + 1;
      }
      return undefined;
    },
    queryKey: newsKeys.all,
    initialPageParam: 0,
    queryFn: ({ pageParam }) =>
      newsApi.getNews({ limit: LIMIT, offset: pageParam * 10 }),
    initialData: initData,
    refetchOnMount: !initData,
  });
};
